<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Event Categories ({{ totalItems }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn
        v-if="hasCreatePermission"
        color="primary"
        class="appbar__btn"
        height="40px"
        :to="{ name: 'event.category.create' }"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Add Category
      </v-btn>
    </v-app-bar>

    <div
      class="mx-4"
      id="category-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-spacer />

        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search category"
          @input="search"
        ></v-text-field>
        <template>
          <v-select
            class="ml-10"
            hide-details
            dense
            item-text="label"
            item-value="value"
            v-model="defaultFilter"
            :disabled="loading"
            :items="filterBy"
            style="max-width: 200px"
            @change="filterCategory"
          />

          <v-btn
            depressed
            class="ml-2"
            :disabled="loading"
            :color="sortOrder ? 'primary' : ''"
            @click="
              sortOrder = true
              filterCategory()
            "
          >
            <v-icon>{{ icons.arrowUp }}</v-icon>
          </v-btn>
          <v-btn
            depressed
            class="ml-2"
            :disabled="loading"
            :color="!sortOrder ? 'primary' : ''"
            @click="
              sortOrder = false
              filterCategory()
            "
          >
            <v-icon>{{ icons.arrowDown }}</v-icon>
          </v-btn>
        </template>
      </div>

      <v-simple-table>
        <thead>
          <tr>
            <th class="text-left">Category</th>
            <th class="text-left">Updated date</th>
            <th class="text-left">Added date</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="category in categories"
            :key="category.id"
            class="clickable"
            @click="
              $router.push({
                name: 'event.category.details',
                params: { id: category.id },
              })
            "
          >
            <td>{{ category.label }}</td>
            <td>{{ category.updatedDate }}</td>
            <td>{{ category.addedDate }}</td>
          </tr>

          <template v-if="loading">
            <tr v-for="index in 10" :key="index + '-skeleton'">
              <td v-for="index in 3" class="pl-0" :key="index">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>

      <div
        class="d-flex justify-center mt-3"
        v-if="!(categories.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Event Category list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiArrowDown, mdiPlus } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import CATEGORY_TYPE from '@/utils/enums/CategoryType'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  name: 'CategoriesPage',

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
      },
      defaultFilter: 'label',
      sortOrder: false,
      filterBy: [
        {
          value: 'label',
          label: 'Category',
        },
        {
          value: 'created_at',
          label: 'Added date',
        },
        {
          value: 'updated_at',
          label: 'Updated date',
        },
      ],
    }
  },

  created() {
    this.clearCategories()
    this.fetchCategories(1)
  },

  computed: {
    ...mapState({
      categories: (state) => state.category.list,
      listMeta: (state) => state.category.listMeta,
      permissions: (state) => state.auth.permissions,
    }),

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },

    hasCreatePermission() {
      return validatePermissions([PERMISSION.EVENTS_CREATE], this.permissions)
    },
  },

  methods: {
    ...mapActions({
      getCategories: 'category/getCategories',
    }),

    ...mapMutations({
      clearCategories: 'category/clearCategoryList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
    }),

    filterCategory(filterValue) {
      this.clearCategories()
      this.fetchCategories(null, filterValue)
    },

    loadMore() {
      if (
        this.listMeta &&
        this.listMeta.current_page < this.listMeta.last_page
      ) {
        this.fetchCategories(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.clearCategories()
      this.fetchCategories()
    }, 600),

    async fetchCategories(page = 1) {
      this.setLoadingBuilding(true)
      if (this.loading) return

      let params = {
        paginate: true,
        type: CATEGORY_TYPE.event,
        page,
        sort: this.sortOrder
          ? `${this.defaultFilter}`
          : `-${this.defaultFilter}`,
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.loading = true
      await this.getCategories(params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}
</style>
